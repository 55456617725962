var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body"},[(_vm.claimed && _vm.claimedItem)?_c('RewardDialog',{attrs:{"callbackClose":_vm.exitRwd,"showDialog":true,"rewards":[
          {
            Item: {
              images: {
                icon: JSON.parse(_vm.claimedItem.translatableImage)[_vm.$_getLocale()]
                  ? JSON.parse(_vm.claimedItem.translatableImage)[_vm.$_getLocale()]
                      .url
                  : JSON.parse(_vm.claimedItem.translatableImage)[
                      _vm.getSubjectLang(_vm.claimedItem)
                    ].url,
              },
            },
            quantity: 1,
          },
        ]}}):_vm._e(),_c('v-container',{staticClass:"app-body"},[(_vm.api.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.api}}):_vm._e(),_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" ABC "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit}})],1),_c('div',{staticClass:"px-5"},[(_vm.otherBooks.length > 0)?_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("string.newArrivals")))]),_vm._l((_vm.otherBooks),function(item,i){return _c('v-card',{key:i + 'all',staticClass:"my-2 list-card",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('v-avatar',{staticClass:"ma-3",attrs:{"size":"125","tile":""}},[(item.translatableImage)?_c('v-img',{attrs:{"src":JSON.parse(item.translatableImage)[_vm.$_getLocale()]
                        ? JSON.parse(item.translatableImage)[_vm.$_getLocale()]
                            .url
                        : JSON.parse(item.translatableImage)[
                            _vm.getSubjectLang(item)
                          ].url}}):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column justify-space-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"text-h5 mx-2 pt-3"},[_vm._v(" "+_vm._s(JSON.parse(item.translatableName)[_vm.getSubjectLang(item)])+" ")]),_c('v-card-actions',[_c('v-chip',{attrs:{"small":"","dark":""}},[_vm._v(_vm._s(item.subject))]),_c('v-spacer'),_c('v-btn',{staticClass:"text-h6",class:item.type == 'premium' ? '' : 'constant-tilt-shake',attrs:{"color":item.type == 'premium' ? 'grey' : 'green',"dark":"","loading":_vm.api.isLoading && _vm.selectedBook == item},on:{"click":function($event){return _vm.claimBook(item)}}},[(item.type == 'premium')?_c('v-icon',[_vm._v(" mdi-lock ")]):_c('div',[_vm._v(_vm._s(_vm.$t("action.claim")))])],1)],1)],1)],1)])})],2):_vm._e(),(_vm.myBooks.length > 0)?_c('div',[_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("string.myBook")))]),_vm._l((_vm.myBooks),function(item,i){return _c('v-card',{key:i + 'own',staticClass:"my-2 list-card",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('v-avatar',{staticClass:"ma-3",attrs:{"size":"125","tile":""}},[(item.translatableImage)?_c('v-img',{attrs:{"src":JSON.parse(item.translatableImage)[_vm.$_getLocale()]
                        ? JSON.parse(item.translatableImage)[_vm.$_getLocale()]
                            .url
                        : JSON.parse(item.translatableImage)[
                            _vm.getSubjectLang(item)
                          ].url}}):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column justify-space-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"text-h5 mx-2 pt-3"},[_vm._v(" "+_vm._s(JSON.parse(item.translatableName)[_vm.getSubjectLang(item)])+" ")]),(item.PlayerBook)?_c('div',{staticClass:"px-3"},[(item.progress < 1)?_c('v-progress-linear',{attrs:{"color":"blue","rounded":"","height":"8","value":item.progress * 100}}):_c('v-progress-linear',{attrs:{"color":"green","rounded":"","height":"8","value":100}})],1):_vm._e(),_c('v-card-actions',{staticClass:"flex-wrap"},[_c('v-chip',{staticClass:"my-2",attrs:{"small":"","dark":""}},[_vm._v(_vm._s(item.subject))]),_c('v-spacer'),(item.progress < 1)?_c('v-btn',{staticClass:"text-h6 constant-tilt-shake",attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.read(item)}}},[_vm._v(" ▶ "+_vm._s(_vm.$t("string.read"))+" ")]):_c('v-btn',{staticClass:"text-h6",attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.read(item)}}},[_vm._v(" ✔ "+_vm._s(_vm.$t("string.read"))+" ")])],1)],1)],1)])})],2):_vm._e()])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }